<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>smiley-happy</title>
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="12" cy="12" r="11.25" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M5.634,14.25a.75.75,0,0,0-.707,1,7.5,7.5,0,0,0,14.146,0,.75.75,0,0,0-.707-1Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M16.5,7.875a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.5,7.875a.375.375,0,1,1-.375.375A.375.375,0,0,1,7.5,7.875"
    />
  </svg>
</template>
